import React, {
  Suspense,
  // , useEffect, useState
} from "react";
// import { Switch, Route } from "react-router-dom";
// import { Cookies } from "react-cookie";
import {
  Redirect,
  // , useHistory
} from "react-router-dom";
import PhysicalLeadContaxtProvider from "../../context/physical_lead";
import RealTestDriveContaxtProvider from "../../context/real_test_drive";
import {
  // useIsAuthDispatch,
  useIsAuthState,
} from "../../hooks/useAuth";
// import { useSetUserDispatch, useUser } from "../../context/user";
// import { checkCookieIsEnabled } from "../../utils/auth/check_navigator_cookie";
import { getDisabledComponent } from "../../utils/auth/get_disabled_component";
import { useLocation } from "react-router-dom";
import Loading from "../common/loading/loading";

// import { useDrawerState } from "../../hooks/useDrawer";

const UserTicket = React.lazy(() =>
  import("../admin-component/ticketing/user_ticket")
);
const TicketReplays = React.lazy(() =>
  import("../admin-component/ticketing/ticket_replay")
);
const BillOfSell = React.lazy(() =>
  import("../admin-component/bill-of-sell/bill_of_sell")
);

const DealerRealTestDrive = React.lazy(() =>
  import("../admin-component/real-test-drive/dealer_real_test_drive")
);
const AddDealerRealTestDrive = React.lazy(() =>
  import("../admin-component/real-test-drive/add_dealer_real_test_drive")
);
const DealerVehicleManage = React.lazy(() =>
  import("../admin-component/vehicle/vehicle_manage")
);
const DealerVehicleManagement = React.lazy(() =>
  import("../admin-component/vehicle/dealer_vehicle_managment")
);
const EditVehicleManagement = React.lazy(() =>
  import("../admin-component/vehicle/edit_vehicle_management")
);
const EditVinNumber = React.lazy(() =>
  import("../admin-component/vehicle/parts/vin_edit")
);

const VehicleVendor = React.lazy(() =>
  import("../admin-component/vehicle/vehicle-vendor/vehicle_vendor")
);
const ChangePassword = React.lazy(() =>
  import("../admin-component/user-profile/user_change_password")
);
const MultiWindowSticker = React.lazy(() =>
  import("../admin-component/window-sticker/mutli_select_window_sticker")
);
const UserUpdateProfile = React.lazy(() =>
  import("../admin-component/user-profile/user_update_profile")
);
const Pricing = React.lazy(() =>
  import("../admin-component/pricing/pricing_component")
);
const SellerNegotiates = React.lazy(() =>
  import("../admin-component/negotiates-seller/seller_negotitates")
);
const SellerSingleDealPage = React.lazy(() =>
  import("../admin-component/negotiates-seller/seller_single_deal_page")
);
const SellerSingleInventoryInDealPage = React.lazy(() =>
  import(
    "../admin-component/negotiates-seller/seller_single_inventory_in_deal_page"
  )
);
const EditUser = React.lazy(() =>
  import("../admin-component/user-managment/dealership_edit_user")
);
const SingleNotification = React.lazy(() =>
  import("../admin-component/notfication/single_notification")
);
const AllNotifications = React.lazy(() =>
  import("../../components/admin-component/notfication/all_notifications")
);
const UserTasks = React.lazy(() =>
  import("../admin-component/tasks/user_tasks")
);

const AddCustomerForSeller = React.lazy(() =>
  import("../seller-component/add-customer/add_custommer")
);
const BillNotInPerson = React.lazy(() =>
  import("../admin-component/bill-of-sell/bill_not_in_person")
);
const OfflineChat = React.lazy(() =>
  import("../admin-component/offline-chat/offline-chat")
);
const AllCustomers = React.lazy(() =>
  import("../admin-component/all-customers/all_customers")
);
const PrivateRoute = React.lazy(() => import("./private_route"));
const PackageProvider = React.lazy(() => import("../../context/package"));
const SellerHomePage = React.lazy(() =>
  import("../seller-component/home/seller_home_page")
);
const AllCars = React.lazy(() =>
  import("../admin-component/all-cars/all_cars")
);
const LeadCenter = React.lazy(() => import("../admin-component/lead_center"));
const PhysicalLeadMainWizard = React.lazy(() =>
  import(
    "../admin-component/admin-lead-center/physical-lead/physical_lead_main_wizard"
  )
);
const SellerAllCustomers = React.lazy(() =>
  import("../seller-component/seller-customers/seller_customers")
);
const CustomerProfiles = React.lazy(() =>
  import("../admin-component/all-customers/customer_profiles")
);
const CustomersLead = React.lazy(() =>
  import("../admin-component/all-customers/customers_lead")
);
const CustomersDeal = React.lazy(() =>
  import("../admin-component/all-customers/customers_deal")
);
const CustomersInventory = React.lazy(() =>
  import("../admin-component/all-customers/customers_inventory")
);
const CustomersBHPH = React.lazy(() =>
  import("../admin-component/all-customers/customers_bhph")
);
const BillOfSaleForSingleVehicleInventory = React.lazy(() =>
  import("../admin-component/vehicle/bill_of_sale_for_single_vehicle")
);
const VehicleManagementCaringlistTable = React.lazy(() =>
  import(
    "../admin-component/vehicle/vehicle-managment-parts/vehicle_managment_caringlist_table"
  )
);
const VehicleManagementFacebookTable = React.lazy(() =>
  import(
    "../admin-component/vehicle/vehicle-managment-parts/vehicle_management_face_book_table"
  )
);
const EditTask = React.lazy(() => import("../admin-component/tasks/edit_task"));
const MechanicShop = React.lazy(() =>
  import("../admin-component/vehicle/mechanic-shop/mechanic_shop")
);
const DeatilMechanicShop = React.lazy(() =>
  import(
    import(
      "../admin-component/vehicle/mechanic-shop/service_list_parts_mecanic_shop/deatil_mechanicShop"
    )
  )
);
const ServiceRoutes = () => {
  const auth = useIsAuthState();
  const {
    components: { ds_components, role_components },
  } = auth;
  const location = useLocation();
  if (location.pathname === "/" && auth.ds_status > 4) {
    return <Redirect to="/admin" />;
  }

  return (
    <div className="p-0 full-main-page ">
      <Suspense fallback={() => <Loading />}>
        <PackageProvider>
          <PrivateRoute
            isAuth={auth.isLoggedIn}
            exact
            path="/admin/pricing"
            component={Pricing}
          />
        </PackageProvider>
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealerRealTestDrive"
          )}
          exact
          path="/admin/dealer-real-test-drive"
          component={
            role_components.indexOf("DealerRealTestDrive") !== -1
              ? DealerRealTestDrive
              : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AddDealerRealTestDrive"
          )}
          exact
          path="/admin/dealer-real-test-drive/add"
          component={
            // role_components.indexOf("AddDealerRealTestDrive") !== -1
            // ?
            (props) => (
              <RealTestDriveContaxtProvider>
                <AddDealerRealTestDrive />
              </RealTestDriveContaxtProvider>
            )
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "DealerVehicleManage"
          // )}
          exact
          path="/admin/add-vehicle"
          component={
            // role_components.indexOf("DealerVehicleManage") !== -1
            //   ?
            DealerVehicleManage
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealerVehicleManagement"
          )}
          exact
          path="/admin/vehicle-managment"
          component={DealerVehicleManagement}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditVehicleManagement"
          // )}
          exact
          path="/admin/edit-vehicle-managment/:id"
          component={
            // role_components.indexOf("EditVehicleManagement") !== -1
            //   ?
            EditVehicleManagement
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "VehicleVendor"
          )}
          exact
          path="/admin/vehicle-vendor"
          component={
            role_components.indexOf("VehicleVendor") !== -1
              ? VehicleVendor
              : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "VehicleVendor"
          )}
          exact
          path="/admin/vehicle-vendor/:id"
          component={
            role_components.indexOf("VehicleVendor") !== -1
              ? VehicleVendor
              : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "EditVinNumber"
          )}
          exact
          path="/admin/edit-vehicle"
          component={
            role_components.indexOf("EditVinNumber") !== -1
              ? EditVinNumber
              : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "UserUpdateProfile"
          // )}
          exact
          path="/admin/update-profile"
          component={
            //   role_components.indexOf("UserUpdateProfile") !== -1

            // ?
            UserUpdateProfile
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/edit-user/:id"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "EditUser"
          )}
          component={
            // role_components.indexOf("EditUser") !== -1
            // ?
            EditUser
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "ChangePassword"
          )}
          exact
          path="/admin/change-password"
          component={
            role_components.indexOf("ChangePassword") !== -1
              ? ChangePassword
              : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "MultiWindowSticker"
          )}
          exact
          path="/admin/window-sticker"
          component={
            role_components.indexOf("MultiWindowSticker") !== -1
              ? MultiWindowSticker
              : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "SellerNegotiates"
          // )}
          exact
          path="/admin/seller-negotiates"
          component={
            // role_components.indexOf("SellerNegotiates") !== -1
            // ?
            SellerNegotiates
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "SellerSingleDealPage"
          // )}
          exact
          path="/admin/seller-negotiates/deal/:id"
          component={
            // role_components.indexOf("SellerSingleDealPage") !== -1
            // ?
            SellerSingleDealPage
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "SellerSingleInventoryInDealPage"
          // )}
          exact
          path="/admin/seller-negotiates/deal/inventory/:id"
          component={
            // role_components.indexOf("SellerSingleInventoryInDealPage") !== -1
            // ?
            SellerSingleInventoryInDealPage
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AllNotifications"
          )}
          exact
          path="/admin/notfication/"
          component={
            role_components.indexOf("AllNotifications") !== -1
              ? AllNotifications
              : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "SingleNotification"
          )}
          exact
          path="/admin/notfication/:id"
          component={
            role_components.indexOf("SingleNotification") !== -1
              ? SingleNotification
              : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/bill-of-sale/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSell"
          // )}
          component={
            // role_components.indexOf("BillOfSell") !== -1
            // ?
            BillOfSell
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/user-tasks"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "UserTasks"
          )}
          component={
            // role_components.indexOf("UserTasks") !== -1
            // ?
            UserTasks
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/seller-add-customer"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AddCustomerForSeller"
          // )}
          component={
            // role_components.indexOf("AddCustomerForSeller") !== -1
            // ?
            AddCustomerForSeller
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/bill-not-in-person"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillNotInPerson"
          // )}
          component={
            // role_components.indexOf("BillNotInPerson") !== -1
            // ?
            BillNotInPerson
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AllCustomers"
          // )}
          component={
            // role_components.indexOf("AllCustomers") !== -1
            // ?
            AllCustomers
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/seller-add-customer"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AddCustomerForSeller"
          // )}
          component={
            // role_components.indexOf("AddCustomerForSeller") !== -1
            // ?
            AddCustomerForSeller
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/bill-not-in-person"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillNotInPerson"
          // )}
          component={
            // role_components.indexOf("BillNotInPerson") !== -1
            // ?
            BillNotInPerson
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/offline-chat/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "OfflineChat"
          // )}
          component={
            // role_components.indexOf("OfflineChat") !== -1
            // ?
            OfflineChat
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/user-ticket"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "UserTicket"
          // )}
          component={
            // role_components.indexOf("UserTicket") !== -1
            // ?
            UserTicket
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/ticket-manage/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "TicketReplays"
          // )}
          component={
            // role_components.indexOf("TicketReplays") !== -1
            // ?
            TicketReplays
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "SellerHomePage"
          // )}
          component={
            // role_components.indexOf("SellerHomePage") !== -1
            // ?
            SellerHomePage
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/seller-all-cars"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AllCars"
          // )}
          component={
            // role_components.indexOf("AllCars") !== -1
            // ?
            AllCars
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "LeadCenter"
          // )}
          exact
          path="/lead-center"
          component={
            // role_components.indexOf("LeadCenter") !== -1
            //   // ?
            LeadCenter
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "PhysicalLeadMainWizard"
          // )}
          exact
          path="/lead/add/physical-lead"
          component={
            // role_components.indexOf("PhysicalLeadMainWizard") !== -1
            // ?
            (props) => (
              <PhysicalLeadContaxtProvider>
                <PhysicalLeadMainWizard />
              </PhysicalLeadContaxtProvider>
            )
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "SellerAllCustomers"
          // )}
          exact
          path="/seller/customers"
          component={
            // role_components.indexOf("SellerAllCustomers") !== -1
            // ?a
            SellerAllCustomers
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomerProfiles
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/Leads/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomersLead
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/Deals/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomersDeal
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/Inventory/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomersInventory
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/BHPH/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomersBHPH
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/vehicle-managment/single-vehicle/deal/:id"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            (props) => (
              // <PhysicalLeadContaxtProvider>
              <BillOfSaleForSingleVehicleInventory />
              // </PhysicalLeadContaxtProvider>
            )
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/advertisment/craigslist"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            VehicleManagementCaringlistTable
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/advertisment/facebook"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            VehicleManagementFacebookTable
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/user-tasks/task-managment/:id"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            EditTask
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "MechanicShop"
          // )}
          exact
          path="/admin/mechanic-shop"
          // component={
          //   // role_components.indexOf("UserUpdateProfile") !== -1
          //   //   ?
          //   AttachAndNote
          //   // : () => <Redirect to="/admin" />
          // }
          component={(props) => <MechanicShop {...props} />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "MacanicShopServiceDetail"
          // )}
          exact
          path="/admin/mechanic-shop/service/:id"
          // component={
          //   // role_components.indexOf("UserUpdateProfile") !== -1
          //   //   ?
          //   AttachAndNote
          //   // : () => <Redirect to="/admin" />
          // }
          component={(props) => <DeatilMechanicShop {...props} />}
        />
      </Suspense>
    </div>
  );
  // }

  // history.replace("/signin");
};

export default ServiceRoutes;
